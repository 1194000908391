@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.asset-import {
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 6px;
  // via https://kovart.github.io/dashed-border-generator/
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='black' stroke-width='2' stroke-dasharray='13%2c 17' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
}
.drop-zone {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  &.is-dragging {
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(#a5d6a7, 0.75);
    }
    &:after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f354';
      font-size: 16em;
      color: rgba(black, 0.65);
    }
  }

  &.is-image-imported {
    z-index: -1;
  }

  &__arrow {
    color: black;
    padding: 8px;
    border-radius: 6px;
    // via https://kovart.github.io/dashed-border-generator/
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='black' stroke-width='4' stroke-dasharray='6%2c 12' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  &__desc {
    font-size: 18px;
    color: black;
    margin-top: 22px;
    margin-bottom: 12px;
    width: 100%;
  }

  &__formats {
    font-size: 12px;
    color: $grey-600;
    margin-top: 10px;
    margin-bottom: 26px;
    width: 100%;
  }

  &__select-existing {
    width: 100%;
    margin-bottom: 10px;
    font-size: 15px;
    color: black;
  }

  &__select-existing-btn {
    text-transform: none;
    color: black;
    border: solid 1px $grey-200 !important;
  }
}

.drop-zone__content {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  text-align: center;

  .is-image-imported & {
    display: none;
  }

  .is-dragging & {
    pointer-events: none;
  }
}
