@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.post-schedule {
  @include media('xs-only') {
    margin-right: 15px;
  }
}
.has-red-asterisk {
  position: relative;

  &:before {
    position: absolute;
    left: -8px;
    top: -3px;
    content: '* ';
    color: crimson;
    font-size: 0.9em;
  }
}
