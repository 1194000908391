@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.preset-labels {
  @include media('xs-only') {
    .is-step-3 & {
      margin-right: 15px;
    }
  }
}
.preset-labels__heading {
  margin-bottom: 15px;
  font-size: 0.75em;
  font-weight: bold;

  @include media('sm-and-up') {
    margin-top: 30px;
  }
}
.preset-labels__field-wrapper {
  margin-top: 4px;

  &:first-child {
    margin-top: 0;
  }
}
.v-text-field {
  &.is-default {
    ::v-deep input {
      color: $grey-500;
    }
  }

  ::v-deep .v-text-field__details {
    margin-top: 2px;
    padding-left: 0;
  }

  ::v-deep .v-messages,
  ::v-deep .v-counter {
    font-size: 10.5px;
  }
}
