@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.video {
  position: relative;

  @include media('xs-only') {
    margin-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.video__player {
  width: 100%;
}

.video__clear-btn {
  position: absolute;

  @include media('xs-only') {
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @include media('sm-and-up') {
    right: 0;
    top: -46px;
  }
}
