@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.asset-info {
}
.asset-info__category-select {
  width: 50%;
}
.asset-info__stores {
  @include media('xs-only') {
    ::v-deep tr {
      th:first-child,
      td:first-child {
        padding-left: 0;
      }
    }
  }
}
.has-red-asterisk:before {
  content: '* ';
  color: crimson;
  font-size: 1.2em;
  letter-spacing: -0.5px;
}
