@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.nav {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav__cancel {
  text-transform: none;
}
.nav__back {
  text-transform: none;
}
.nav__title {
  white-space: nowrap;
}
.nav__next {
  text-transform: none;
}
