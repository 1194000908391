@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.stepper-header {
  height: auto;
}
.stepper-header__container {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  padding-top: 0;
  padding-bottom: 0;

  @media (max-width: 1100px) {
    margin-left: 20px;
  }

  .v-stepper__step {
    padding-left: 0;
    padding-top: 18px;
    padding-bottom: 18px;
    flex-basis: 35%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  ::v-deep .v-stepper__step__step {
    font-weight: bold;
    margin-right: 8px;
  }

  ::v-deep .v-stepper__label {
    display: flex !important;
  }
}
